import { createApp } from 'vue';
import App from './App.vue';
import store from './store'; // 引入 Vuex store
import router from './router'; // 引入 Vue Router

document.documentElement.style.setProperty('--header-background-color', process.env.VUE_APP_HEADER_BACKGROUND_COLOR);
document.documentElement.style.setProperty('--header-font-color', process.env.VUE_APP_HEADER_FONT_COLOR);
document.documentElement.style.setProperty('--footer-background-color', process.env.VUE_APP_FOOTER_BACKGROUND_COLOR);
document.documentElement.style.setProperty('--footer-font-color', process.env.VUE_APP_FOOTER_FONT_COLOR);
document.documentElement.style.setProperty('--header-selected-font-color', process.env.VUE_APP_HEADER_SELECTED_FONT_COLOR);
document.documentElement.style.setProperty('--button-background-color', process.env.VUE_APP_BUTTON_BACKGROUND_COLOR);
document.documentElement.style.setProperty('--product-list-font-color', process.env.VUE_APP_PRODUCT_LIST_FONT_COLOR);
document.documentElement.style.setProperty('--currency-font-color', process.env.VUE_APP_CURRENCY_FONT_COLOR);
document.documentElement.style.setProperty('--cart-count-background-color', process.env.VUE_APP_CART_COUNT_BACKGROUND_COLOR);
document.documentElement.style.setProperty('--link-selected-color', process.env.VUE_APP_LINK_SELECTED_COLOR);
document.documentElement.style.setProperty('--homepage-price-font-size', process.env.VUE_APP_HOMEPAGE_PRICE_FONT_SIZE);
document.documentElement.style.setProperty('--product-price-font-size', process.env.VUE_APP_PRODUCT_PRICE_FONT_SIZE);
document.documentElement.style.setProperty('--feature-background-color', process.env.VUE_APP_FEATURE_BACKGROUND_COLOR);
document.documentElement.style.setProperty('--feature-border-color', process.env.VUE_APP_FEATURE_BORDER_COLOR);
document.documentElement.style.setProperty('--feature-text-color', process.env.VUE_APP_FEATURE_TEXT_COLOR);


const app = createApp(App);
app.use(store);  // 使用 Vuex store
app.use(router); // 使用 Vue Router
app.mount('#app'); // 挂载应用

if (process.env.NODE_ENV === 'production') { // 在 production disable 所有 console log display
    console.log = function() {};
    console.warn = function() {};
    console.error = function() {}; // 您也可以选择保留 console.error
}